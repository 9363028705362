import { createTheme } from '@mui/material/styles'

const mainColor = paletteType => {
  if (!paletteType || paletteType === 'light') {
    return {
      main: '#000000'
    }
  } else {
    return {
      main: '#ffffff'
    }
  }
}

const defaultTheme = paletteType => {
  let theme = createTheme({
    palette: {
      mode: paletteType || 'light',
      primary: mainColor(paletteType),
      secondary: {
        light: '#f73378',
        main: '#f50057',
        dark: '#ab003c',
        contrastText: '#000',
      },
    },
    components: {
      // Name of the component ⚛️
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple!
        },
      },
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1350, // was 1200
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500
    },
    // overrides: {
    //   MuiLink: {
    //     root: {
    //       color: (!paletteType || paletteType === 'light') ? '#1976d2' : '#a6d4fa'
    //     }
    //   },
    // },
    typography: {
      h6: {
        fontWeight: 600,
        fontFamily: 'Inter, Roboto, Arial'
      },
      h5: {
        fontWeight: 800,
        // TODO fixes a but where CardHeader adds spacing to the text
        fontFamily: 'Inter, Roboto, Arial'
      },
      h4: {
        fontWeight: 600,
        fontFamily: 'Inter, Roboto, Arial'
      },
      h2: {
        fontWeight: 600,
        fontFamily: 'Inter, Roboto, Arial'
      },
      h1: {
        fontWeight: 700,
        fontFamily: 'Inter, Roboto, Arial'
      }
    },

  })

  // Material ui can't use this, but it can be manually unpacked 
  theme.typography.article = {
    fontFamily: 'Crimson Pro',
    fontSize: '1.375rem',
    fontWeight: 400,
    // letterSpacing: "0.00938em",
    // lineHeight: 1.5
  }

  return theme
} 

export default defaultTheme