import React from 'react'

import { ReactComponent as Story } from './story.svg'
import SvgIcon from '@mui/material/SvgIcon'

const NewsIcons = props => {
  return <SvgIcon 
    fontSize={props.fontSize || 'small'}
    sx={props.sx || {}}
    htmlColor={props.htmlColor || 'inherit'}
  >
    <Story />
  </SvgIcon>
}

export default NewsIcons