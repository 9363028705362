import { createTheme } from '@mui/material/styles'

const bwTheme = paletteType => {
  if (paletteType === 'light' || !paletteType) {
    return createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#ffffff',
        },
        action: {
          active: '#000000',
        },
      },
    })
  } else if (paletteType === 'dark') {
    return createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: '#000000',
        },
        action: {
          active: '#ffffff',
        },
      },
    })
  }
}

export default bwTheme