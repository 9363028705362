import React, {
  useState,
  useRef,
} from 'react';

import { useNavigate } from 'react-router-dom';
import OverlayContext from './OverlayContext.js';

import NavMenu from './NavMenu';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Render the dialog to the page, then open it
const OverlayDialog = props => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const [atBottom, setAtBottom] = useState(false);
  const boxRef = useRef(null);
  const [node, setNode] = useState(null);

  const handleScroll = event => {
    
    var customEvent = new CustomEvent('spiritedScroll', {
      detail: 'overlay'
    })
    document.dispatchEvent(customEvent)

    if (boxRef.current) {
      let rect = boxRef.current.getBoundingClientRect()
      let offset = window.innerHeight

      
      if (Math.floor(rect.bottom) - offset <= 500) {
        setAtBottom(true)

        var endEvent = new CustomEvent('spiritedPageEnd', {
          detail: 'overlay'
        })
        document.dispatchEvent(endEvent)

      } else {
        setAtBottom(false)
      }
    } 
  }

  const dialogNode = newNode => {
    setNode(old => {
      old && old.removeEventListener('scroll', handleScroll, true)

      newNode && newNode.addEventListener('scroll', handleScroll, true)

      return newNode
    })
  }

  const containerRef = useRef(null)
  // const divRef = useRef(null)

  const scrollDialog = (x, y) => {
    console.log('scrollDialog', node)
    console.log('node', node.scrollTop, node.clientHeight)
    console.log('container', containerRef.current.scrollTop, containerRef.current.clientHeight)
    console.log('box', boxRef.current.scrollTop, boxRef.current.clientHeight)
    node && node.scrollTo(x, y)
    boxRef.current.scrollTo(x, y)
    containerRef.current.scrollTo(x, y)
  }

  const MyComponent = props.route.component

  return <OverlayContext.Provider value={{
    scrollTo: scrollDialog,
    inOverlay: true
  }}>
    <Dialog 
      fullScreen 
      open={open} 
      onClose={handleClose}
      transitionDuration={0}
      ref={dialogNode}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          backgroundImage: 'none'
        }
      }}
    >
      <NavMenu 
        skipCheck={true} 
        isOverlay={true}
        closeButton={handleClose} 
      />
      <Container 
        maxWidth='lg' 
        // className={classes.container}
        // sx={matches ? {
        //   paddingLeft: 0,
        //   paddingRight: 0
        // } : {}}
      >
      <Box 
        mt={9} 
        ref={boxRef}
      >
        <div ref={containerRef}>
          <MyComponent 
            match={props.route.match}
            location={props.route.location}
            params={props.route.match.params}
            atBottom={atBottom}
            scrollDialog={scrollDialog}
          />
        </div>
      </Box>
      </Container>
    </Dialog>
  </OverlayContext.Provider>
}

// The overlayed page is frozen at this location. It should not reload, it
// can only be removed
const pathChange = (prevProps, nextProps) => {
  // return prevProps.route.path === nextProps.route.path 
  return true
}

export default React.memo(OverlayDialog, pathChange)
// export default OverlayDialog