import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const largeInputTheme = theme => {
  return createTheme(theme, {
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            fontSize: 50,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            fontSize: 50,
            color: grey[400],
            '&.Mui-focused': {
              fontSize: 20,
              color: grey[400],
            },
            '&.Mui-filled': {
              fontSize: 20,
              color: grey[400],
            }
          },
        },
      }
    },
  });
}

export default largeInputTheme;