import React, {
  useEffect,
} from 'react'

import WebFont from 'webfontloader'

import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import { useTheme } from '@mui/material/styles'

const Landing = props => {
  const theme = useTheme()

  useEffect(() => {

    WebFont.load({
      google: {
        families: [`Crimson Pro:n4,i4,n7,i7`]
      }
    })
    
  },[])

  return <Container maxWidth="sm">
    <Grid
      sx={{
        height: '100vh', 
        paddingBottom: theme.spacing(15)
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      wrap='nowrap'>
      <Grid 
        item container 
        justifyContent='center'
        alignItems='center'
        direction='column'
        spacing={2}>
        <Typography 
          variant="h2" 
          sx={{
            fontWeight: 700,
          }}
          // className={classes.title}
        >
          PostText
        </Typography>
      </Grid>
      <Grid 
        item 
        // className={classes.space}
        sx={{
          marginTop: theme.spacing(3)
        }}
      >
        <CircularProgress />
      </Grid>
    </Grid>
  </Container>
}

export default Landing