import React from 'react'
// import { withStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

const DialogTitle = props => {
  const theme = useTheme()

  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle 
      // disableTypography 
      // className={classes.root} 
      sx={{
        m: 0,
        p: 2,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton 
          size='small'
          aria-label="close" 
          // className={classes.closeButton}
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: theme.palette.grey[500],
          }} 
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export default DialogTitle