import React, {
  useState,
  useContext,
  useEffect,
} from 'react'

import { DatabaseContext } from '../database.js'

import { useLocation, useNavigate } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Forward10Icon from '@mui/icons-material/Forward10'
import Replay10Icon from '@mui/icons-material/Replay10'
import PauseIcon from '@mui/icons-material/Pause'

const AudioPlayer = props => {
  const context = useContext(DatabaseContext)
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const [currentTime, setCurrentTime] = useState(context.audioEl.currentTime || 0)

  useEffect(() => {
    if (props.streamingUrl) {
      if (props.inEditor) {
        context.loadStreamingAudio(props.streamingUrl)
      } else if (props.inArticle) {
        context.loadStreamingAudio(
          props.streamingUrl, 
          location.pathname,
          props.title
        )
      } 
    }
    // eslint-disable-next-line 
  },[props.streamingUrl])

  useEffect(() => {
    if (context.audioCurrentTime) {
      setCurrentTime(context.audioCurrentTime)
    }
  },[context.audioCurrentTime])

  const handlePlay = event => {
    // context.audioEl.play()
    // setPlaying(true)
    context.audioPlay(true)
  }

  const handlePause = event => {
    // context.audioEl.pause()
    // setPlaying(false)
    context.audioPlay(false)
  }

  const forward = event => {
    context.audioEl.currentTime += 10
  }

  const backward = event => {
    context.audioEl.currentTime -= 10
  }

  const handleSliderChange = (event, newValue) => {
    context.audioEl.currentTime = newValue
  }

  const timeString = num => {
    if (!num) {
      return '0:00'
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let seconds = Math.round(num % 60)
    let minutes = Math.floor(num / 60)
    let hours = Math.floor(num / 3600)

    if (num < 3600) {
      return `${minutes}:${zeroPad(seconds, 2)}`
    } else {
      return `${hours}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`
    }
  }

  // Use the document title to create a file name
  const fileName = () => {
    let strs =  props.fileUrl.split('.')
    let ext = strs[strs.length - 1]
    return props.title.replace(' ','') + ext
  }

  const downloadFile = event => {
    console.log('downloadFile')
    event.preventDefault()
    event.stopPropagation()

    var a = document.createElement('a')
    a.href = props.audioDoc.data().fileUrl
    a.download = fileName()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleTitleClick = event => {
    event.preventDefault()
    event.stopPropagation()

    navigate(context.audioRoute)
  }

  const shortTitle = () => {
    const maxLen = 30
    if (context.audioTitle.length > maxLen) {
      return context.audioTitle.slice(0,maxLen) + '...'
    } else {
      return context.audioTitle
    }
  }

  // // Attach listeners for audio events
  // useEffect(() => {
  //   const updateCurrentTime = () => {
  //     setCurrentTime(timeString(context.audioEl.currentTime))
  //     setTimeVal(context.audioEl.currentTime)
  //   }

  //   const updateDuration = () => {
  //     setDuration(timeString(context.audioEl.duration))
  //     setDurationVal(context.audioEl.duration)
  //   }

  //   const endAudio = () => {
  //     // setPlaying(false)
  //     context.audioPlay(false)
  //   }

  //   context.audioEl.addEventListener('timeupdate', updateCurrentTime)
  //   context.audioEl.addEventListener('canplay', updateDuration)
  //   context.audioEl.addEventListener('ended', endAudio)

  //   return () => {
  //     context.audioEl.removeEventListener('timeupdate', updateCurrentTime)
  //     context.audioEl.removeEventListener('canplay', updateDuration)
  //     context.audioEl.removeEventListener('ended', endAudio)
  //   }
  // },[context.audioEl])

  if (props.inNav) {
    return <Grid 
      container 
      direction='row' 
      alignItems='center'
      justifyContent='center'
    >
      {!matches &&
        <Grid item>
          <Link 
            href={context.audioRoute}
            color='textPrimary'
            onClick={handleTitleClick}
          >
            {shortTitle()}
          </Link>
        </Grid>
      }
      {context.audioPlaying ? (
        <Grid item>
          <IconButton onClick={handlePause}>
            <PauseIcon fontSize='small'/>
          </IconButton>
        </Grid>
      ):(
        <Grid item>
          <IconButton onClick={handlePlay}>
            <PlayArrowIcon fontSize='small'/>
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <Typography variant='caption'>
          {timeString(context.audioCurrentTime)}
        </Typography>
      </Grid>
    </Grid>
  } else {
    return <Grid container direction='column' wrap='nowrap'>
      <Grid 
        item container 
        direction='row' 
        wrap='nowrap'
        justifyContent='center'
        spacing={4}
        alignItems='flex-start'
      >
        <Grid item xs={6}/>
        <Grid item>
          <IconButton onClick={backward}>
            <Replay10Icon fontSize='large'/>
          </IconButton>
        </Grid>
        {context.audioPlaying ? (
          <Grid item>
            <IconButton onClick={handlePause}>
              <PauseIcon fontSize='large'/>
            </IconButton>
          </Grid>
        ):(
          <Grid item>
            <IconButton onClick={handlePlay}>
              <PlayArrowIcon fontSize='large'/>
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton onClick={forward}>
            <Forward10Icon fontSize='large'/>
          </IconButton>
        </Grid>
        <Grid 
          item container 
          xs={6}
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
        >
          {props.deleteButton &&
            <Grid item >
              {props.deleteButton}
            </Grid>
          }
        </Grid> 
      </Grid>
      <Grid 
        item container 
        direction='row' 
        wrap='nowrap'
        justifyContent='center'
        spacing={4}
        alignItems='center'
      >
        <Grid item>
          <Typography variant='body1'>
            {timeString(context.audioCurrentTime)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider 
            value={currentTime} 
            max={context.audioDuration}
            onChange={handleSliderChange} 
          />
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            {timeString(context.audioDuration)}
          </Typography>
        </Grid>
      </Grid>
      <Grid 
        item container 
        direction='row' 
        wrap='nowrap'
        justifyContent='flex-end'
        spacing={4}
        alignItems='center'
      >
        <Grid item>
          <Link 
            href={props.fileUrl}
            variant='body2'
            onClick={downloadFile}
            download={fileName()}
          >
            Download File
          </Link> 
        </Grid>
      </Grid>
    </Grid>
  }
}

export default AudioPlayer