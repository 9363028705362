import React, {
  useState,
  useContext,
  useRef
} from 'react'

import { DatabaseContext } from '../database.js'
import { validateEmail } from '../util'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

const EmailLinkSignIn = props => {

  const context = useContext(DatabaseContext);
  const theme = useTheme();

  const [email, setEmail] = useState(context.lastEmailLogin || '');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const handleEmailChange = event => {
    setEmail(event.target.value);
    setError(null);
  }

  // const [verifier, setVerifier] = useState(null);

  // Every time the submit butten changes, re-attach the verifier
  // useCallback prevents this from happening every keystroke
  const submitButtonRef = useRef();

  const handleSend = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError('Invalid email address');
      return;
    }

    setSending(true);

    var recaptchaVerifier = new context.auth
    .RecaptchaVerifier(submitButtonRef.current, {
      'size': 'invisible',
      'callback': function(response) {
        // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
        onSolvedRecaptcha();
      },
    });

    recaptchaVerifier.render();

    recaptchaVerifier.verify();
  }

  const onSolvedRecaptcha = () => {
    console.log('onSolvedRecaptcha email', email);

    const actionCodeSettings = {
      url: props.url || window.location.href,
      // This must be true.
      handleCodeInApp: true
    }
    
    console.log('will sendSignInLinkToEmail', actionCodeSettings);

    context.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      console.log('success')
      setSending(false);
      setSent(true);
      window.localStorage.setItem('emailForSignIn', email);

      context.analytics?.logEvent('email_signin')
    })
    .catch(error => {
      console.log('error', error);
      setError(error.message);
    });
  }

  const title = () => {
    if (props.title) {
      return props.title
    } else if (context.showSignIn?.title) {
      return context.showSignIn.title;
    }
  }

  const message = () => {
    if (props.message) {
      return props.message
    } else if (context.showSignIn?.message) {
      return context.showSignIn.message;
    }
  }

  return <Paper
    sx={{
      padding: theme.spacing(2)
    }}
  >
    {!sent && 
      <React.Fragment>
        <Grid item container 
          direction='row' 
          wrap='nowrap'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography variant='h6'>
            {title() ? (
              <React.Fragment>
                {title()}
              </React.Fragment>
            ) : (
              'Sign In'
            )}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={props.close}
              size='small'
              sx={{
                mt: -1,
                mr: -1
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body1' gutterBottom>
            {message() ? (
                <React.Fragment>
                  {message()}
                </React.Fragment>
              ):(
                "An email address is all it takes to get started. "
              )
            }
            You will receive a log in link in your email.
          </Typography>
        </Grid>
        <Grid item>
          <form 
            noValidate autoComplete="off" 
            onSubmit={handleSend}>
            <Grid container 
              direction='column'
              wrap='nowrap'
              spacing={2}>
              <Grid item>
                <TextField
                  disabled={sending}
                  fullWidth
                  label='Email'
                  type='email'
                  variant='outlined'
                  onChange={handleEmailChange} />
              </Grid>
              {error &&
                <Grid item>
                  <Alert severity="warning">
                    {error}
                  </Alert>
                </Grid>
              }
              <Grid item container justifyContent='flex-end'>
                <Button
                  disabled={sending}
                  // className={classes.button}
                  sx={{
                    marginLeft: 'auto'
                  }}
                  onClick={handleSend}
                  variant='contained'
                  color='primary'
                  ref={submitButtonRef}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </React.Fragment>
    }
    {sent && 
      <React.Fragment>
        <Grid item container 
          direction='row' 
          wrap='nowrap'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography variant='h6'>
              Link Sent
            </Typography>
          </Grid>   
          <Grid item>
            <IconButton
              onClick={props.close}
              size='small'
              sx={{
                mt: -1,
                mr: -1
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            We sent a login link to <b>{email}</b>. Open the link to log in.
          </Typography>
        </Grid>
        {error && 
          <Grid item>
            <Alert severity='warning'>
              {error}
            </Alert>
          </Grid>
        }
        <Grid item container direction='row' justifyContent='flex-end'>
          <Grid item>
            <Button
              onClick={props.close}
              variant='contained'
            >
              Ok
            </Button>
          </Grid>
        </Grid>  
      </React.Fragment>
    }
  </Paper>
}

export default EmailLinkSignIn;