import React from 'react'

import * as Sentry from "@sentry/react"

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import ErrorIcon from '@mui/icons-material/Error'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      error: null,
      showError: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: error }
  }

  componentDidCatch(error, info) {
    console.log('error caught')

    this.setState({ 
      error: error 
    })

    Sentry.captureException(error)
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({showError: true})
  }

  render() {
    if (this.state.error) {
      return <Grid 
        container 
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          height: '100%'
        }}
      >
        <Grid item>
          <ErrorIcon sx={{ fontSize: 60 }} color='error'/>
        </Grid>
        <Grid item>
          <Typography variant='h5'>
            Something went wrong
          </Typography>
        </Grid>
        <Grid item>
          <Link 
            variant='body1' 
            onClick={this.handleClick}
            href={'/error'}
          >
            More info...
          </Link>
        </Grid>
        {this.state.showError &&
          <Grid item>
            <Alert severity='error'>
              {this.state.error.message}
            </Alert>
          </Grid>
        }
      </Grid>      

    }
    return this.props.children
  }
}

export default ErrorBoundary