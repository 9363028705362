import React from 'react'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const UnsafeLink = props => {
  return <Container maxWidth="sm">
    <Typography variant='h3'>
      Not Found
    </Typography>
    <Typography variant='body1'>
      This document couldn't be found
    </Typography>
  </Container>
}

export default UnsafeLink