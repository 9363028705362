import React, {
  useContext,
  useState
} from 'react'

import { DatabaseContext } from '../database.js'

import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import DialogTitle from './DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import StoryIcon from '../icons/StoryIcon'
import NewsIcon from '../icons/NewsIcon'
import DrawerIcon from '../icons/DrawerIcon'
import MaskIcon from '../icons/MaskIcon'

import HomeIcon from '@mui/icons-material/Home'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import EmailIcon from '@mui/icons-material/Email'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const LeftMenu = props => {
  const navigate = useNavigate()
  const theme = useTheme()
  const context = useContext(DatabaseContext)

  const routeTo = route => {
    props.toggleDrawer && props.toggleDrawer()

    if (route.charAt(0) === '/') {
      // When opening a system route, see if the browser is currently open
      // to a system route. If it is, replace the current route with the
      // new one. 
            // let nextRouteObj = routeMap.find(r => r.path === route)
      // let currentRouteObj = routeMap.find(r => r.path === location.pathname)
      // if (nextRouteObj?.action === 'system' 
      //   && currentRouteObj?.action === 'system') {
      //   console.log('replace')
      //   navigate(route, {replace: true})
      // } else {
      //   console.log('do not replace')
      //   navigate(route)
      // }
      navigate(route)
    } else if (route.startsWith('http')) {
      window.open(route)
    }
  }

  const innerSpacing = 1.5

  const handleOpen = (event, title, message) => {
    props.toggleDrawer && props.toggleDrawer();

    context.promptSignIn(title, message);
  }

  const [anchor, setAnchor] = useState(null)

  const handleClose = event => {
    setAnchor(null)
  }

  const [open, setOpen] = useState(false)
  // const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleDialogClose = event => {
    setOpen(false)
  }

  const handleDialogOpen = event => {
    setOpen(true)
    handleClose()
  }

  const handlePaste = async (event) => {
    let url = event.clipboardData.getData("text")
    // setValue(url)
    setLoading(true)
    setError(null)
    let result = await context.getBoostTarget(url)

    if (result.error) {
      setError(result.error)
    } else {
      setError(null)
      setOpen(false)
      let route = `/responses/${result.boostTargetId}`
      navigate(route)
    }

    setLoading(false)
  }

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    event.stopPropagation();

    navigate(path);
  }

  const menuButton = (icon, label, route) => {
    const linkClick = event => {
      event.preventDefault();
      event.stopPropagation();

      routeTo(route);
    }

    return <Card 
      variant='outlined'
      sx={{
        margin: 0, 
        border: 0
      }}
    >
      <CardActionArea 
        onClick={event => routeTo(route)}
      >
        <Grid  
          container 
          direction='row' 
          spacing={innerSpacing} 
          alignItems='center'
          wrap='nowrap'
        >
          <Grid item>
            {icon}
          </Grid>
          <Grid item>
            <Link 
              variant='h5'
              href={route}
              onClick={linkClick}
              sx={{
                textDecoration: 'none',
              }}
            >
              {label}
            </Link>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  }

  return <React.Fragment>
    <Paper
      sx={{
        position: 'sticky',
        top: theme.spacing(9),
        // This makes sticky work
        // https://www.designcise.com/web/tutorial/how-to-fix-issues-with-css-position-sticky-not-working
        alignSelf: 'flex-start',
        border: 0
      }}
      variant='outlined'
    >
      <Grid container direction='column' wrap='nowrap'spacing={1.5}>
        <Grid item>
          {menuButton(<HomeIcon fontSize='large' />, 'Home', '/')}
        </Grid>
        <Grid item>
          {menuButton(<NewsIcon fontSize='large' />, 
            'Articles', '/browse')}
        </Grid>
        <Grid item>
          {menuButton(<StoryIcon fontSize='large' />, 
            'Library', '/library')}
        </Grid>
        {context.user &&
          <React.Fragment>
            <Grid item>
              {menuButton(<DrawerIcon fontSize='large' />, 'Archive', '/archive')}
            </Grid>
            <Grid item>
              {menuButton(<MaskIcon fontSize='large' />, 'Profiles', '/identities')}
            </Grid>
            <Grid item>
              {menuButton(<RecordVoiceOverIcon fontSize='large' />, 'Following', '/following')}
            </Grid>
            {context.subscriptions?.length > 0 &&
              <Grid item>
                {menuButton(<EmailIcon fontSize='large' />, 'Notifications', '/email-notifications')}
              </Grid>
            }
            {context.stripeSubscriptions?.length > 0 &&
              <Grid item>
                {menuButton(<CheckCircleIcon fontSize='large' />, 'Subscriptions', '/subscriptions')}
              </Grid>
            }
            <Grid item>
              <Card 
                variant='outlined'
                sx={{
                  margin: 0, 
                  border: 0
                }}
              >
                <CardActionArea onClick={event => setAnchor(event.currentTarget)}>
                  <Grid  
                    container 
                    direction='row' 
                    spacing={innerSpacing} 
                    alignItems='center'
                    wrap='nowrap'
                  >
                    <Grid item>
                      <Avatar
                        size='small'
                        sx={{
                          border: `2px solid ${theme.palette.primary.main}`,
                          color: theme.palette.primary.main,
                          backgroundColor: theme.palette.background.paper
                        }}
                      >
                        <MoreHorizIcon 

                        />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant='h5'>
                        More
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
              <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={handleClose}
                sx={{
                  zIndex: 1400
                }}
              >
                <MenuItem onClick={handleDialogOpen}>Lookup website comments</MenuItem>
              </Menu>
            </Grid>
            <Grid item>
              <Link 
                href='/contact' 
                onClick={event => handleLinkClick(event, '/contact')}
                variant='body2'
              >
                Contact Us
              </Link>
            </Grid>
            <Grid item>
              <Link 
                href='/privacy' 
                onClick={event => handleLinkClick(event, '/privacy')}
                variant='body2'
              >
                Privacy Policy
              </Link>
            </Grid>
            <Grid item>
              <Link 
                href='/tos' 
                onClick={event => handleLinkClick(event, '/tos')}
                variant='body2'
              >
                Terms of Service
              </Link>
            </Grid>
          </React.Fragment>
        }
        {!context.user && 
          <React.Fragment>
            <Divider 
              // className={classes.margin}
              sx={{
                marginTop: theme.spacing(1.5),
              }}
            />
            <Grid item>
            <Typography 
              variant='h6' 
              // className={classes.heading}
              sx={{
                fontWeight: 600
              }}
            >
              You're Not Signed In
            </Typography>
            <Typography variant='body2' gutterBottom>
              Create a free account to comment, write articles and more.
            </Typography>
            </Grid>
            <Grid item>
            <Button
              variant='contained'
              size='large'
              color='primary'
              onClick={event => handleOpen(event, 'Sign Up')}
              fullWidth
              // className={classes.buttonMargin}
              sx={{
                marginBottom: theme.spacing(1)
              }}
            >
              Sign Up
            </Button>
            </Grid>
            <Grid item>
            <Button
              variant='outlined'
              size='large'
              color='primary'
              onClick={event => handleOpen(
                event, 
                'Log in to an existing account', 
                "Enter your email address to access your account. "
              )}
              fullWidth
            >
              Log In
            </Button>
            </Grid>
            <Grid item>
              <Link 
                href='/contact' 
                onClick={event => handleLinkClick(event, '/contact')}
                variant='body2'
              >
                Contact Us
              </Link>
            </Grid>
            <Grid item>
              <Link 
                href='/privacy' 
                onClick={event => handleLinkClick(event, '/privacy')}
                variant='body2'
              >
                Privacy Policy
              </Link>
            </Grid>
            <Grid item>
              <Link 
                href='/tos' 
                onClick={event => handleLinkClick(event, '/tos')}
                variant='body2'
              >
                Terms of Service
              </Link>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </Paper>
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth='xs'
      fullWidth
      sx={{
        zIndex: 1475
      }}
    >
      <DialogTitle
        onClose={handleDialogClose}
      >
        Webpage Comments
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          Find the PostText comment page for a website
        </Typography>
        <TextField 
          variant='outlined'
          label='Paste URL'
          sx={{
            mt: 2
          }}
          onPaste={handlePaste}
          fullWidth
        />
        {loading &&
          <LinearProgress />
        }
        {error &&
          <Alert 
            severity='warning'
            sx={{
              mt: 1
            }}
          >
            {error}
          </Alert>
        }
      </DialogContent>
    </Dialog>

  </React.Fragment>
}

export default LeftMenu