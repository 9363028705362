import React from 'react';

import App from '../app/App'
import defaultTheme from '../app/defaultTheme'

import '../index.css'
// NOTE installed an imported Roboto because it was failing to load 
// inexplicably 
// import 'typeface-roboto'
// import "@fontsource/roboto"
import WebFont from 'webfontloader'

import "core-js/stable"
import "regenerator-runtime/runtime"

import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/*************************
 * Bug Tracker
 *************************/
import * as Sentry from "@sentry/react"

if (!(window.location.hostname === "localhost")) {
// if (true) {
  Sentry.init({
    dsn: "https://5dfc93b687c84ee380721c218216a992@o462303.ingest.sentry.io/5465549",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        // useLocation,
        // useNavigationType,
        // createRoutesFromChildren,
        // matchRoutes
      }),
    ],
    // The number of transactions to send to the server as a percentage
    // With a large number of users this can be reduced below 100%
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV
  })
}

WebFont.load({
  google: {
    families: ['Inter', 'Roboto']
  },
  active: () => {
    // console.log('font active!')
  }
})

const theme = defaultTheme()

const AppServer = () => {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>  
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </LocalizationProvider>
}

export default AppServer;