import React from 'react'

import { ReactComponent as Drawer } from './drawer.svg'
import SvgIcon from '@mui/material/SvgIcon'

const DrawerIcon = props => {
  return <SvgIcon 
    fontSize={props.fontSize || 'small'}
    sx={props.sx || {}}
    htmlColor={props.htmlColor || 'inherit'}
  >
    <Drawer />
  </SvgIcon>
}

export default DrawerIcon