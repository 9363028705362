import React from 'react'

import { useTheme } from '@mui/material/styles'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const CloseButton = props => {
  const theme = useTheme()

  return <IconButton 
    size='small'
    aria-label="close" 
    sx={{
      position: 'absolute',
      right: 1,
      top: 1,
      color: theme.palette.grey[500],
      zIndex: 2000
    }} 
    onClick={props.onClose}>
    <CloseIcon />
  </IconButton>
} 

export default CloseButton