import React from 'react'

// const scrollTo = (x, y) => {
//   console.log('no dialog')
// }

const OverlayContext = React.createContext({
  scrollTo: null,
  inOverlay: false
});

export default OverlayContext;