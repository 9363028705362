import React from 'react';

import { Helmet } from 'react-helmet';

const Head = props => {

  const title = props.title || "PostText | Share what you're reading";
  const description = props.description || "Create posts with quotes, follow other readers and access our library for free.";

  return <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
}

export default Head;