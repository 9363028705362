import React, {
  useContext
} from 'react'
import { useNavigate } from 'react-router-dom'
import { DatabaseContext } from '../database.js'

import Landing from './Landing'

const LoadingWrapper = props => {
  const context = useContext(DatabaseContext)
  const navigate = useNavigate()

  if (!context.user && context.authLoading) {
    return <Landing />
  } else if (!context.authLoading && !context.user) {
    console.log('LoadingWrapper redirect')
    // return redirect('/')
    navigate('/')
  } else {
    return <React.Fragment>
      {props.children}
    </React.Fragment> 
  }
}

export default LoadingWrapper