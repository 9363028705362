import React from 'react';
import { DatabaseContext } from '../database.js';
import { docObj, saveObj, deepMerge, cleanURL, getGoogleBook, 
  googleBookToBt, removeEmpty, createBtId} from '../util';
import defaultTheme from './defaultTheme';
import WebAppRouter from './WebAppRouter';

// Firebase v9 imports 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";

import sbd from 'sbd';
// import Hls from 'hls.js'

import {
  BrowserRouter
} from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/app-check';

import * as Sentry from "@sentry/react";

import ErrorBoundary from './ErrorBoundary';
import Head from './Head';

const devCreds = {
  apiKey: "AIzaSyCWhcjsd2yReN039neRNQ5w-3viK7kb0s8",
  authDomain: "spirited-dev-308718.firebaseapp.com",
  projectId: "spirited-dev-308718",
  storageBucket: "spirited-dev-308718.appspot.com",
  messagingSenderId: "205088280267",
  appId: "1:205088280267:web:1c26f7a06ec2ae0545954e",
  measurementId: "G-D0GC430Y8C"
}

const prodCreds = {
  apiKey: "AIzaSyDkRlb6aDRkk-A1aBH5JDhq02K6n9zU_cU",
  authDomain: "spirited-prod.firebaseapp.com",
  projectId: "spirited-prod",
  storageBucket: "spirited-prod.appspot.com",
  messagingSenderId: "944487645452",
  appId: "1:944487645452:web:4bc00d7d86b3e812598929",
  measurementId: "G-9XMWTQB81L"
}

const creds = process.env.NODE_ENV === 'production' ? prodCreds : devCreds
// const creds = prodCreds
// const creds = devCreds

const recaptchaKey = process.env.NODE_ENV === 'production' 
  // prod key
  ? '6LfEy1keAAAAAEvY4Wo7TLdr87ISVeZMnVJQUlas' 
  // dev key
  : '6LcDNlQeAAAAAFnFqFtS6zuLZ7ExWXPaWybBrJo_'

const stripeKey = process.env.NODE_ENV === 'production' 
// Prod key
? 'pk_live_51MujFTFt3x4a7KUm8jf8VkoJYZnqxycf6KS9wH6ZxmqNxnPKNox8sCvIOaLKZEV8tecJp8mh07aq4RRpVijJutHH001KGEgDBd'
// Test key
: 'pk_test_51MujFTFt3x4a7KUmae5qVQASIEvUv3GubNOKEwKIhMVQkpvktfS8aT0MN2nyyO8XqLUaDw924lOYr8t5m5Iesfya00pdL7OeG2'

// // prod
// let recaptchaKey = '6LfEy1keAAAAAEvY4Wo7TLdr87ISVeZMnVJQUlas'
// // dev
// let recaptchaKey = '6LcDNlQeAAAAAFnFqFtS6zuLZ7ExWXPaWybBrJo_' 

const storageName = name => {
  let env = process.env.NODE_ENV 
  if (env === 'production') {
    return 'prod-' + name
  } else {
    return 'dev-' + name
  }
}

export default class App extends React.Component {
  constructor() {
    super()

    // Initialize Cloud Firestore through Firebase
    if (!(firebase.apps.length > 0)) {
      firebase.initializeApp(creds)

      Sentry.setTag('project-id', creds.projectId)
      Sentry.setTag('location', 'client')

      if (process.env.NODE_ENV === 'production') {
        const appCheck = firebase.appCheck()
        appCheck.activate(
          // Second argument enables auto-refresh
          new firebase.appCheck.ReCaptchaEnterpriseProvider(recaptchaKey, true)
        )

        // Gets a new token when the app first loads
        // NOTE I was seeing an issue where tokens were expired when the app
        // first loaded
        appCheck.getToken(true)
      }
    }
    
    // Firebase instances
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    // const db = new FirestoreWrapper(firebase.firestore(), writeToSession)

    const db = firebase.firestore()
    const storage = firebase.storage()
    const functions = firebase.functions()
    const analytics = firebase.analytics.isSupported() && firebase.analytics()

    const palette = window.localStorage.getItem(
      storageName('spirited-palette')
    )
    const theme = defaultTheme(palette)

    // // ************ DO NOT DELETE ************ 
    // // Use emulators
    // // Go to functions/run_emulators.ps1 to see how to start emulators.
    // if (window.location.hostname === "localhost") {
    //   db.useEmulator("localhost", 8080)
    //   functions.useEmulator("localhost", 5001)
    // }
    // // ****************************************

    this.userSettings = this.userSettings.bind(this)
    this.nullUser = this.nullUser.bind(this)
    this.setUpApp = this.setUpApp.bind(this)
    this.clearStoredValues = this.clearStoredValues.bind(this)
    this.liveUpdateAlias = this.liveUpdateAlias.bind(this)
    this.decrementBoosts = this.decrementBoosts.bind(this)
    this.updateCurrentAlias = this.updateCurrentAlias.bind(this)
    this.loadBoostTarget = this.loadBoostTarget.bind(this)
    this.savePreference = this.savePreference.bind(this)
    this.deletePreference = this.deletePreference.bind(this)
    this.addRecentSource = this.addRecentSource.bind(this)
    this.addTimeStamp = this.addTimeStamp.bind(this)
    this.getNextBoost = this.getNextBoost.bind(this)
    this.urlFromLocation = this.urlFromLocation.bind(this)
    this.getBoostTarget = this.getBoostTarget.bind(this)
    // this.storeAnnotation = this.storeAnnotation.bind(this)
    // this.removeStoredAnnotation = this.removeStoredAnnotation.bind(this)
    this.storeComment = this.storeComment.bind(this)
    // this.removeStoredComment = this.removeStoredComment.bind(this)
    this.storeBoostTarget = this.storeBoostTarget.bind(this)
    this.removeStoredBoostTarget = this.removeStoredBoostTarget.bind(this)
    // this.refreshUser = this.refreshUser.bind(this)
    this.alert = this.alert.bind(this)
    this.removeAlert = this.removeAlert.bind(this)
    this.newPost = this.newPost.bind(this)
    this.closePost = this.closePost.bind(this)
    this.deleteNotification = this.deleteNotification.bind(this)
    this.actionReCAPTCHA = this.actionReCAPTCHA.bind(this)
    this.reCAPTCHA = this.reCAPTCHA.bind(this)
    this.promptSignIn = this.promptSignIn.bind(this)
    this.deleteCancelled = this.deleteCancelled.bind(this)
    this.clearPrompt = this.clearPrompt.bind(this)
    this.togglePalette = this.togglePalette.bind(this)
    this.setArticleTheme = this.setArticleTheme.bind(this)
    this.loadRecaptchaScript = this.loadRecaptchaScript.bind(this)
    this.receiveSelections = this.receiveSelections.bind(this)
    this.commentOfSelection = this.commentOfSelection.bind(this)
    this.storeAudioEl = this.storeAudioEl.bind(this)
    this.loadStreamingAudio = this.loadStreamingAudio.bind(this)
    this.audioPlay = this.audioPlay.bind(this)
    this.setLastEmailLogin = this.setLastEmailLogin.bind(this)
    this.setAtBottom = this.setAtBottom.bind(this)
    this.addChapterMenu = this.addChapterMenu.bind(this)
    this.removeChapterMenu = this.removeChapterMenu.bind(this)
    this.toggleMute = this.toggleMute.bind(this)
    this.hasStripeSubscription = this.hasStripeSubscription.bind(this)
    this.addNewPosts = this.addNewPosts.bind(this);
    this.updateVerificationStatus = this.updateVerificationStatus.bind(this);
  
    this.state = {
      firestore: firebase.firestore,
      auth: firebase.auth,
      db: db,
      storage: storage,
      functions: functions,
      projectId: creds.projectId,
      creds: creds,
      stripeKey: stripeKey,
      analytics: analytics,
      authLoading: true,
      uid: null,
      authUser: null,
      user: null,
      tags: null,
      currentAlias: null,
      timeStamp: () => { 
        return firebase.firestore.FieldValue.serverTimestamp() 
      },
      theme: theme,
      aliases: [],
      fonts: [],
      preferences: {},
      following: [],
      clearStoredValues: this.clearStoredValues,
      liveUpdateAlias: this.liveUpdateAlias,
      decrementBoosts: this.decrementBoosts,
      boostDecrements: 0,
      updateCurrentAlias: this.updateCurrentAlias,
      loadBoostTarget: this.loadBoostTarget,
      btImageUrl: this.btImageUrl,
      savePreference: this.savePreference,
      deletePreference: this.deletePreference,
      addRecentSource: this.addRecentSource,
      addTimeStamp: this.addTimeStamp,
      lastBoosts: null,
      lastBounties: null,
      getNextBoost: this.getNextBoost,
      deleteViewedBoost: this.deleteViewedBoost,
      urlFromLocation: this.urlFromLocation,
      getBoostTarget: this.getBoostTarget,
      storeComment: this.storeComment,
      removeStoredComment: this.removeStoredComment,
      storeBoostTarget: this.storeBoostTarget,
      removeStoredBoostTarget: this.removeStoredBoostTarget,

      alert: this.alert,
      removeAlert: this.removeAlert,
      newPost: this.newPost,
      closePost: this.closePost,
      deleteNotification: this.deleteNotification,
      redirect: null,
      creatingUser: false,
      actionReCAPTCHA: this.actionReCAPTCHA,
      reCAPTCHA: this.reCAPTCHA,
      promptSignIn: this.promptSignIn,
      deleteCancelled: this.deleteCancelled,
      clearPrompt: this.clearPrompt,
      togglePalette: this.togglePalette,
      articleTheme: null,
      setArticleTheme: this.setArticleTheme,
      processingSelections: [],
      loadStreamingAudio: this.loadStreamingAudio,
      audioPlay: this.audioPlay,
      setLastEmailLogin: this.setLastEmailLogin,
      setAtBottom: this.setAtBottom,
      addChapterMenu: this.addChapterMenu,
      removeChapterMenu: this.removeChapterMenu,
      chapterMenus: [],
      newQuotes: [],
      toggleMute: this.toggleMute,
      getStripeSubscription: this.getStripeSubscription,
      subscriptions: [],
      stripeSubscriptions: [],
      hasStripeSubscription: this.hasStripeSubscription,
      newPosts: [],
      getNewPosts: this.getNewPosts,
      addNewPosts: this.addNewPosts,
      updateVerificationStatus: this.updateVerificationStatus,
    }
  }

  scrollEvent(event) {
    var newEvent = new CustomEvent('spiritedScroll', {
      detail: 'window'
    })
    document.dispatchEvent(newEvent)
  }

  componentDidMount() {   
    const onError = error => {
      console.log('Login error', error.message);
    }
    // On page load firebase will still have to log in, so this function 
    // will run even if the user is logged in with a cookie. It also runs 
    // if they log in through the UI
    this.state.auth().onAuthStateChanged(this.setUpApp, onError);

    this.loadRecaptchaScript();

    window.addEventListener('scroll', this.scrollEvent);

  }

  componentWillUnmount() {
    document.removeEventListener('sendSelections', this.receiveSelections)
    window.removeEventListener('scroll', this.scrollEvent)
  }

  async userSettings(userData, newState) {
    let currentAlias
    if (window.localStorage) {
      currentAlias = window.localStorage.getItem(
        storageName('currentAlias')
      )
    }

    if (!currentAlias) {
      currentAlias = userData.aliases[0] || null
      window.localStorage.setItem(
        storageName('currentAlias'), 
        currentAlias
      )
    }

    newState = Object.assign({}, newState, {
      user: userData, 
      authLoading: false,
      currentAlias: currentAlias
    })
    
    let promises = []
    promises.push(this.loadAliases(userData))
    promises.push(this.loadPreferences(userData))
    promises.push(this.loadSystemNotes(userData))
    promises.push(this.loadFollowing(userData))
    promises.push(this.loadMuted(userData))
    promises.push(this.loadSubscriptions(userData))
    promises.push(this.loadStripeSubscriptions(userData))
    
    let results = await Promise.all(promises)

    newState['aliases'] = results[0]
    newState['preferences'] = results[1]
    newState['systemNotes'] = results[2]
    newState['following'] = results[3]
    newState['muted'] = results[4]
    newState['subscriptions'] = results[5]
    newState['stripeSubscriptions'] = results[6]

    newState['userSetup'] = true

    return newState
  }

  nullUser() {
    const update = {
      uid: null, 
      user: null, 
      authUser: null,
      authLoading: false,
      currentAlias: null,
      aliases: null, 
      preferences: null,
      userSetup: false
    }

    this.setState(Object.assign({}, this.state, update))

    this.forceUpdate()
  }

  async setUpApp(authUser) {
    console.log('auth state changed',authUser)
    // authUser only exists when they are logged in
    if (authUser) {
      let newState = {
        authUser: authUser,
        uid: authUser.uid, 
        isAnonymous: authUser.isAnonymous
      }

      // When a new user is created, there's a short delay 
      // before we have permission to read their user document.
      // Make sure we have permission to read it before proceeding.
      let canReadUserDoc = false;
      while (!canReadUserDoc) {
        try {
          const userDoc = await this.state.db.collection('users')
          .doc(authUser.uid).get()

          if (userDoc.exists) {
            canReadUserDoc = true;
          } else {
            console.log('User doc does not exist yet')
            await new Promise(r => setTimeout(r, 1000));
          }
        } catch (error) {
          console.log('Error reading user doc', error)
          await new Promise(r => setTimeout(r, 1000));
        }
      }

      this.state.userSub && this.state.userSub();

      const userSub = this.state.db.collection('users')
      .doc(authUser.uid)
      .onSnapshot(userDoc => {
        if (userDoc.exists) {
          let userData = docObj(userDoc)

          if (this.state.userSetup) {
            // The user settings have been loaded, just update the user doc
            //this.setState({user: userData})
            this.setState((state, props) => {
              let finalData = Object.assign({}, userData)

              return {user: finalData}
            })
          } else {
            // Do the initial user setup using the user data
            this.userSettings(userData, newState)
            .then(finalState => {
              this.setState(finalState)
            })
          }

          console.log('----- Do extension work')

          // Listen for selection data from the extension script
          // This is set up after user data because user data is needed for 
          // comment creation.
          document.addEventListener('sendSelections', 
            event => this.receiveSelections(event, userData.uid));
        
          // Request existing selections once
          var event = new CustomEvent('requestSelections');
          document.dispatchEvent(event);
        } else {
          console.warn('User without user document')
          // User auth accounts and user documents are created at the same
          // time in the emailSignIn cloud function. There should never be a 
          // user with an auth account but no user document.
          this.state.auth().signOut().then(() => {
            this.state.clearStoredValues()
          }).catch(function(error) {
            console.log('Sign out error: ')
            console.log(error)
          })
        }
      }, error => {
        console.warn('User doc error', error.message);
      })

      this.setState({userSub: userSub})
      // const userDoc = await this.state.db.collection('users')
      // .doc(authUser.uid).get()
      // if (userDoc.exists) {
      //   // The user document already exists, get settings for the user
      //   let userData = docObj(userDoc)
        
      //   let finalState = await this.userSettings(userData, newState)

      //   this.setState(finalState)
      // } else if (!this.creatingUser) {
      //   // They have a user record but no document, which means it needs
      //   // to be created for the first time
      //   this.setState({creatingUser: true})
      //   let createUser = this.state.functions.httpsCallable('createUser')
      //   let results = await createUser()
      //   project.logEvent('create_new_user')

      //   if (results.data.userData) {
      //     let finalState = await this.userSettings(results.data.userData, newState)
      //     this.setState(finalState)
      //     this.setState({creatingUser: false})
      //   } else {
      //     this.nullUser()
      //   }

      // } else {
      //   console.warn('')
      // }
    } else {
      this.setState({authLoading: true})

      console.log('authUser false',authUser)
      if (this.state.userSub) {
        this.state.userSub()
      }
      this.nullUser()
    }
  } 

  async receiveSelections(event, uid) {
    let selections = event.detail;
    console.log('PostText App got new selections',selections);

    if (!(selections && Array.isArray(selections))) {
      console.warn('Selections is not an array');
      return
    } else if (selections.length === 0) {
      console.log('No selections to load');
      return
    }

    // // Show a snackbar saying selections are being loaded
    // this.alert('Loading extension quotes...')

    // Filter out selections that are currently being processed
    selections = selections.filter(s => {
      return !Boolean(this.state.processingSelections.find(p => {
        return p.url === s.url && p.selection === s.selection
      }))
    })

    // Store the selections being processed
    this.setState((state, props) => {
      if (state.processingSelections) {
        return {
          processingSelections: state.processingSelections.concat(selections)
        }
      } else {
        return { processingSelections: selections }
      }
    })

    // Get comments from selections
    let promises = []
    for (var sel of selections) {
      if (sel.url && sel.selection) {
        promises.push(this.commentOfSelection(sel, uid))
      }
    }

    let objs = await Promise.all(promises)

    console.log('----- objs', objs)

    for (var obj of objs) {
      // Save the comments in the db
      let ref = this.state.db.collection('comments').doc()
      await ref.set(obj.comment)
      obj.comment.id = ref.id
    }

    console.log('----- now remove selections')

    // Remove the selections that were made into comments from chrome storage
    this.setState((state, props) => {
      let remaining = state.processingSelections.filter(s => {
        return !Boolean(objs.find(o => o.selection.url === s.url))
      }) 

      return {processingSelections: remaining}
    })

    // Remove the message that selections are being loaded
    this.removeAlert()

    // Store the selections so they can be shown immediatly on the Quotes page
    this.setState({newQuotes: objs.map(o => o.comment)})

    // Tell the extension that the comments have been created so it can
    // delete them from storage
    var saveEvent = new CustomEvent('didSaveSelections', 
    { detail: selections })
    document.dispatchEvent(saveEvent)
  }

  async commentOfSelection(selection, uid) {
    let btData = await this.getBoostTarget(selection.url)

    let html = selection.selection.split('\n')
    .filter(s => s.length > 0)
    .map(s => `<p>${s}</p>`)
    .join('')

    let comment = {
      annotation: {
        "@context": "http://www.w3.org/ns/anno.jsonld",
        sentenceArray: sbd.sentences(selection.selection, {
          "newline_boundaries" : true
        }),
        sentenceHTML: html,
        target: {
          selector: {
            prefix: '',
            exact: selection.selection,
            suffix: ''
          },
          source: btData,
        },
        type: "Annotation",
        algoliaSaved: false
      },
      creator: {
        uid: uid,
      },
      timeStamp: this.state.timeStamp(),
    }

    return {
      comment: comment,
      selection: selection
    }

  }

  // async refreshUser() {
  //   // const querySnapshot = await this.state.db.collection('users')
  //   // .where('uid','==',this.state.uid).get()
  //   // if (querySnapshot.docs.length > 0) {
  //   //   let userDoc = querySnapshot.docs[0]
  //   //   console.log('update user')
  //   //   this.setState({user: docObj(userDoc)})
  //   // }
  //   const userDoc = await this.state.db.collection('users')
  //   .doc(this.state.uid).get()
  //   if (userDoc.exists) {
  //     this.setState({user: docObj(userDoc)})
  //   }
  // }

  // Store alias data in context so it only needs to be loaded once
  async loadAliases(user) {
    let aliasIds = user.aliases

    // Firestore 'in' query allows a max of 10 array items
    let idGroups = []
    for (var i = 0; i < aliasIds.length; i = i+10) {
      idGroups.push(aliasIds.slice(i, i+10))
    }

        let promises = []

    for (var ids of idGroups) {
      promises.push(
        this.state.db.collection('aliases')
        .where(this.state.firestore.FieldPath.documentId(),'in',ids)
        .get()
      )
    }

    let results = await Promise.all(promises)

    let aliases = []
    for (var querySnapshot of results) {
      for (var doc of querySnapshot.docs) {
        aliases.push(doc)
      }
    }

    return aliases
  }

  async loadPreferences(user) {
    let querySnapshot = await this.state.db.collection('preferences')
    //.where('userId','==',user.id).get()
    .where('uid','==',user.uid).get()
    let docs = querySnapshot.docs
    if (docs.length > 0) {
      const prefs = docObj(docs[0])
      return prefs
    } else {
      console.log('no prefs for this users')
      return []
    }
  }

  async loadSystemNotes(user) {
    let querySnapshot = await this.state.db.collection('notifications')
    .where('uid', '==', user.uid)
    .where('name', '==', 'spiritedMessage')
    .get()
    let docs = querySnapshot.docs

    if (docs.length > 0) {
      return docs.map(doc => docObj(doc))
    } else {
      return []
    }
  }

  // async loadAccountNotes(user) {
  //   let querySnapshot = await this.state.db.collection('notifications')
  //   .where('uid','==',user.uid)
  //   .where('messageTypes','array-contains-any',['account'])
  //   .limit(20)
  //   .get()
  //   let docs = querySnapshot.docs
  //   if (docs.length > 0) {
  //     return docs.map(doc => docObj(doc))
  //   } else {
  //     return []
  //   }
  // }

  // The ids of all the aliases the user follows
  async loadFollowing(user) {
    if (!user.aliases || user.aliases.length === 0) return []

    let querySnapshot = await this.state.db.collection('follows')
    .where('follower.aliasId','in',user.aliases).get()
    let docs = querySnapshot.docs
    if (docs.length > 0) {
      return docs.map(doc => doc.data().following.aliasId)
    } else {  
      return []
    }
  }

  async loadMuted(user) {
    let doc = await this.state.db.collection('users')
    .doc(user.uid).collection('preferences').doc('muted').get()

    if (doc.exists) {
      return doc.data().list
    } else {
      return []
    }
  }

  async loadStripeSubscriptions(user) {
    let querySnapshot = await this.state.db.collection('customers')
    .doc(user.uid)
    .collection('subscriptions')
    .where('plan.active','==',true)
    .get();

    console.log('loaded subscriptions', querySnapshot.docs)

    return querySnapshot.docs
  }

  // async loadMemberships(user) {
  //   let querySnapshot = await this.state.db.collection('memberships')
  //   .where('uid','==',user.uid).get()

  //   return querySnapshot.docs
  // }

  async loadSubscriptions(user) {
    let querySnapshot = await this.state.db.collection('subscriptions')
    .where('subscriber.uid','==',user.uid).get()

    let promises = []

    for (var sub of querySnapshot.docs) {
      promises.push(
        this.state.db.collection('aliases')
        .doc(sub.data().subscribedTo.aliasId).get()
      )
    }

    let results = await Promise.all(promises)

    results = results.filter(r => r.exists)

    return results
  }

  // addMembership(membership) {
  //   let idx = this.state.memberships.findIndex(m => m.id === membership.id)

  //   if (idx > -1) {
  //     let newArr = this.state.memberships.slice()
  //     newArr.splice(idx, 1, membership)

  //     this.setState({memberships: newArr})
  //   } else {
  //     this.setState({memberships: this.state.memberships.concat(membership)})
  //   }
  // }

  hasStripeSubscription(aliasId) {
    return this.state.stripeSubscriptions.some(
      s => s.data().metadata.aliasId === aliasId
    );
  }

  getStripeSubscription(aliasId) {
    return this.state.stripeSubscriptions.find(
      s => s.data().metadata.aliasId === aliasId
    );
  }

  removeSub(subId) {
    let newSubs = this.state.stripeSubscriptions
      .filter(s => s.id !== subId);

    this.setState({stripeSubscriptions: newSubs});
  }

  // getMembership(membershipOptionId) {
  //   return this.state.memberships.find(
  //     m => m.data().membershipOptionId === membershipOptionId
  //   ) 
  // }

  // getMemberships(membershipOptionIds) {
  //   return this.state.memberships.some(
  //     m => membershipOptionIds.includes(m.data().membershipOptionId)
  //   )  
  // }

  // clearExtensionSelection() {
  //   this.setState((state, props) => {
  //     let extension = Object.assign({}, state.extension, {
  //       selection: null
  //     })

  //     return {extension: extension}
  //   })
  // }

  /*
  //   When the app is loaded as an extension perform these steps:
  //   - Get parentURL, the url of the page that the extension is loaded on
  //   - Connect to the chrome tab displaying the extension using parentURL
  //   - Add listeners for messages from content.js, loaded on the parent tab
  //   - Save the port connected to the tab so messages can be sent to it
  // */
  // async extensionSetup() {
  //   ////////////// TESTING //////////////////


  //   // this.setState({
  //   //   extension: {
  //   //     selection: {
  //   //         "rect": {
  //   //             "x": 396.109375,
  //   //             "y": 322.09375,
  //   //             "width": 190.109375,
  //   //             "height": 17,
  //   //             "top": 322.09375,
  //   //             "right": 586.21875,
  //   //             "bottom": 339.09375,
  //   //             "left": 396.109375
  //   //         },
  //   //         "target": {
  //   //             "selector": {
  //   //                 "exact": "know which specific browsers (an",
  //   //                 "prefix": "      \n                Do you ",
  //   //                 "suffix": "d versions) you have been seei"
  //   //             },
  //   //             "source": {
  //   //                 "title": "web - Why is script-src-elem not using values from script-src as a fallback? - Stack Overflow",
  //   //                 "url": "https://stackoverflow.com/questions/64322419/why-is-script-src-elem-not-using-values-from-script-src-as-a-fallback"
  //   //             }
  //   //         },
  //   //         "sentenceHTML": "Do you know which specific browsers (and versions) you have been seeing this happen with? ",
  //   //         "sentenceArray": [
  //   //             "Do you know which specific browsers (and versions) you have been seeing this happen with?",
  //   //             "I have been trying to track down this exact same issue, and I'm wondering if perhaps there is a browser defect causing it."
  //   //         ]
  //   //     }
  //   //   }
  //   // })

  //   /////////////////////////////////////////

  //   const href = window.location.href

  //   console.log('complete href', href)

  //   // TODO also look at the base href, see if it has 'chrome-extension://'
  //   if (!href.includes('?parentURL=')) {
  //     // This is not the extension, do not set extensionURL
  //     console.log('Not an extension')
  //     return false
  //   }

  //   const parentHref = window.location.href.split('?parentURL=')[1]

  //   try {
  //     // If the url is malformed this will throw an error, cancelling the
  //     // rest of the extension setup
  //     const url = new URL(parentHref)
  //     console.log('extension url',url)

  //     this.setState({
  //       extension: {
  //         url
  //       }
  //     })

  //     if (!chrome) {
  //       // 
  //       return false
  //     }

  //     this.setExtensionBt(url)

  //     chrome.tabs.query({url: url + '*'}, tabs => {
  //       if (tabs.length === 0) {
  //         console.log('Could not find tab', this.parentURL())
  //         this.setState({error: "Tab not found"})
  //         return 
  //       }

  //       console.log('tabs', tabs)
  //       let tabId = tabs[0].id
  //       console.log('Sidebar found tabId',tabId)
  //       var port = chrome.tabs.connect(tabId, {name: 'sidebar'})
  //       console.log('Sidebar created port',port)
  //       port.onMessage.addListener(message => {
  //         if (message.selection) {
  //           console.log('selection', message.selection)
  //           this.setState((state, props) => {
  //             let extension = Object.assign({}, state.extension, {
  //               selection: message.selection
  //             })

  //             if (extension.boostTarget) {
  //               let source = extension.selection.target.source
  //               source = Object.assign({}, source, {
  //                 boostTarget: extension.boostTarget,
  //                 boostTargetId: extension.boostTarget.id,
  //               })
  //               extension.selection.target.source = source
  //             }

  //             return {extension: extension}
  //           })
  //         } else if (message.clearSelection) {
  //           console.log('clearSelection')
  //           this.clearExtensionSelection()
  //         } else if (message.ornamentUpdate) {
  //           // this.updateAnnotations(message.ornamentUpdate)
  //           console.log('ornamentUpdate', message.ornamentUpdate)
  //         } else if (message.selected) {
            
  //         }
  //       })

  //       this.setState({
  //         extension: {
  //           url: url,
  //           contentPort: port,
  //         }
  //       })
  //     })
  //   } catch(err) {
  //     console.warn('parentURL:', err)
  //     this.setState((state, props) => {
  //       let extension = Object.assign({}, state.extension, {
  //         error: err.message
  //       })
  //       return {extension: extension}
  //     })
  //     return null
  //   }

  //   return true
  // }

  // // Get the boost target for the parentURL the extension is loaded on
  // async setExtensionBt(urlObj) {

  //   // This accounts for variation in whether parameters must be used
  //   // (YouTube requires them) or not (most websites). iFramely uses the
  //   // appropriate version.
  //   let urls = []
  //   urls.push(urlObj.href)
  //   urls.push(urlObj.origin + urlObj.pathname)

  //   let parentBt
  //   const querySnapshot = await this.state.db.collection('boost_targets')
  //     .where('meta.canonical','in',urls).get()

  //   if (querySnapshot.docs.length > 0) {
  //     console.log('boost target found in db')
  //     parentBt = docObj(querySnapshot.docs[0])
  //   } else {
  //     console.log('no boost target - get bt from cloud',urlObj.href)
  //     const getBoostTarget = this.state.functions.httpsCallable('getBoostTarget')
  //     const result = await getBoostTarget({url: urlObj.href})
  //     parentBt = result.data.boostTarget
  //   }

  //   this.setState((state, props) => {
  //     let extension = state.extension
  //     let result = {}
  //     extension = Object.assign({}, extension, {
  //       boostTarget: parentBt
  //     })

  //     result['extension'] = extension

  //     // TODO If an extension selection exists add this to the source
  //     if (extension.selection) {
  //       let source = extension.selection.target.source
  //       source = Object.assign({}, source, {
  //         boostTarget: parentBt
  //       })

  //       result.extension.selection.target.source = source
  //     }


  //     return result
  //   })
  // }

  loadRecaptchaScript() {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   

    // load the script by passing the URL
    // previous: "https://www.google.com/recaptcha/enterprise.js?render=6LdFg-QZAAAAAKuPNHabhUGpchF7yWX2dAOJ76Aa"
    loadScriptByURL("recaptcha-key", 
      `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaKey}` , function () {
      console.log("recaptcha script loaded!");
    })
  }

  // 
  getNextBoost(boostType) {
    const getBoosts = this.state.functions.httpsCallable('getBoosted')
    let lastDocTime = null
    if (this.state.lastBoosts && boostType === 'boost') {
      lastDocTime = this.state.lastBoosts.slice(-1)[0].boostTarget.oldestBoost
    } else if (this.state.lastBounties && boostType === 'bounty') {
      lastDocTime = this.state.lastBounties.slice(-1)[0].boostTarget.oldestBounty
    }
    return getBoosts({
      number: 1, 
      boostType: boostType,
      lastDocTime: lastDocTime
    }).then(result => {
      if (result.data.length > 0) {
        if (boostType === 'boost') {
          this.setState({lastBoosts: result.data})
        } else if (boostType === 'bounty') {
          this.setState({lastBounties: result.data})
        }
      }
      return result.data
    })
  }

  addRecentSource(boostTargetId) {
    let newRecentSources
    if (this.state.preferences && this.state.preferences.recentSources) {
      newRecentSources = this.state.preferences.recentSources.slice()
      if (newRecentSources.length >= 10) {
        newRecentSources = [boostTargetId].concat(newRecentSources.slice(0,-1))
      } else {
        newRecentSources = [boostTargetId].concat(newRecentSources)
      }
    } else {
      newRecentSources = [boostTargetId]
    }

    newRecentSources = Array.from(new Set(newRecentSources))

    this.savePreference({recentSources: newRecentSources})
  }

  clearStoredValues() {
    this.setState({
      uid: null,
      user: null,
      currentAlias: null,
      aliases: []
    })
  }

  liveUpdateAlias(alias) {
    // Update the alias in the client, regardless of what happens server side
    console.log('liveUpdateAlias',alias)
    let idx = this.state.aliases.findIndex(a => alias.id === a.id)
    if (idx >= 0) {
      // An existing alias has been updated
      let aliases = this.state.aliases.slice()
      aliases.splice(idx, 1, alias)
      this.setState({aliases: aliases})
      console.log('update existing alias')
    } else {
      // A new alias has been added
      this.setState({aliases: this.state.aliases.concat(alias)})
      this.updateCurrentAlias(alias.id)
    }
  }

  updateCurrentAlias(aliasId) {
    // const updateCurrentAlias = this.state.functions
    //   .httpsCallable('updateCurrentAlias')
    // updateCurrentAlias({currentAlias: aliasId})

    // this.setState({
    //   user: Object.assign({}, this.state.user, {currentAlias: aliasId}),
    // })

    if (window.localStorage) {
      window.localStorage.setItem(
        storageName('currentAlias'), 
        aliasId
      )
    }

    this.setState({
      currentAlias: aliasId
    })
  }

  decrementBoosts(number) {
    this.setState({boostDecrements: this.state.boostDecrements + number})
  }

  /*
   * These functions make calls to this.state.db so that they can be 
   * re-written in the extension using background.js
   */

  // Convert a FireStore doc into an data object with id and exists properties
  // The extension react app can only receive objects like this because it 
  // has not direct access to FireStore

  addTimeStamp(obj) {
    return Object.assign({},obj,{
      timeStamp: this.state.firestore.FieldValue.serverTimestamp()
    })
  }

  thumbnailIsLocation(boostTarget) {
    return (boostTarget.links && boostTarget.links.thumbnail) 
      && boostTarget.links.thumbnail[0].location
  }

  btImageUrl(boostTarget) {
    if (boostTarget.links && boostTarget.links.thumbnail) {
      let obj = boostTarget.links.thumbnail[0]
      if (obj.href) {
        return Promise.resolve(obj.href)
      } else if (obj.location) {
        return this.state.storage.ref().child(obj.location)
        .getDownloadURL().then(url => {
          return url
        })
      }
    } else {
      return Promise.resolve(null)
    }
  }

  async iframelyCall(url) {
    const apiCall = `https://iframe.ly/api/iframely?url=${url}&key=${process.env.REACT_APP_IFRAMELY_MD5_KEY}&iframe=1&omit_script=1`
    console.log('apiCall',apiCall)
    const response = await fetch(apiCall) 
    const result = await response.json()
    const cleaned = removeEmpty(result)
    return cleaned
  }

  // Unpacks iframely and spirited boost targets and normalizes them
  async loadBoostTarget(boostTargetId) {
    let doc = await this.state.db.collection('boost_targets')
    .doc(boostTargetId).get()
    
    if (doc.exists) {
      var data = docObj(doc)

      let returnData

      if (data.url || (data.book && !data.googleBookId)) {
        returnData = data
      } else if (data.userUrl) {
        // Handle the case where a url has just been created, not on the
        // server yet
        let result = await  this.iframelyCall(data.userUrl)
        
        console.log('iframelyCall result', result)
        let ifData = Object.assign({}, result, {id: doc.id})
        
        returnData = ifData
      } else if (doc.data().googleBookId) {
        //  If this is a google book load it from the API every time
        let result = await getGoogleBook(doc.data().googleBookId, 
          this.state.creds.apiKey)
        if (result.error) {
          return {error: 'Google book not found'}
        } else {
          let bt = googleBookToBt(result, docObj(doc))
          console.log('google book boost target', bt)
          returnData = bt
        }
      } else {
        return {error: 'Target not found'}
      }

      if (returnData?.response?.boostTarget.googleBookId) {
        let responseBt = await this.loadBoostTarget(returnData.response.boostTargetId)
      
        returnData.response.boostTarget = responseBt

        return returnData
      } else {
        return returnData
      }
    }
  }

  async urlFromLocation(location) {
    return `https://storage.googleapis.com/${this.state.projectId}/${location}`
    // if (sessionStorage) {
    //   try {
    //     let url = sessionStorage.getItem(location)
    //     if (url) {
    //       return url
    //     }
    //   } catch (err) {
    //     return null
    //   }
    // }

    // let url = await this.state.storage.ref().child(location).getDownloadURL()
    // sessionStorage && sessionStorage.setItem(location, url)
    // return url
  }

  async getBoostTarget(url) {
    console.log("url", url)
    const urls = [url, cleanURL(url)]

    // const articleRoute = spiritedUrl(url)
    let pattern = window.location.host + '/article/'
    console.log('pattern', pattern)
    console.log('url', url)
    let articleId
    if (url.includes(pattern)) {
      articleId = url.split(pattern)[1]
    }

    console.log('articleId', articleId)

    // If this is a Post Text article it will have a boost target
    if (articleId) {
      const querySnapshot = await this.state.db.collection('boost_targets')
        .where('articleId','==',articleId).get()

      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0]

        const obj = {
          boostTarget: docObj(doc),
          boostTargetId: doc.id,
          url: url
        }

        return obj
      } else {
        return {
          error: 'Article missing'
        }
      }
    }
    
    // Try to get the boost target for an external url
    const querySnapshot = await this.state.db.collection('boost_targets')
      .where('meta.canonical','in',urls).get()

    if (querySnapshot.docs.length > 0) {
      // We already have this boost target
      const doc = querySnapshot.docs[0]
      return {
        boostTarget: docObj(doc),
        boostTargetId: doc.id,
        url: url
      }
    } else {
      // We don't have a boost target for this url. Create a temp
      const result = await this.iframelyCall(url);

      console.log('result', result);

      if (result.error) {
        console.log('getBoostTarget error',result.error)
        return {error: result.error}
      } else {
        // Create a Google friendly id for the boost target
        const id = createBtId(result.meta.title + ' ' 
        + result.meta.site);

        console.log('id', id);

        const docRef = this.state.db.collection('boost_targets')
        .doc(id);

        // Cloud function will convert this into a full record
        docRef.set({
          userUrl: url, 
          uid: this.state.uid
        })

        const final = Object.assign({}, result, {
          timeStamp: this.state.timeStamp(),
          id: docRef.id
        })

        return {
          boostTarget: final,
          boostTargetId: docRef.id,
          url: url
        }
      }
    }
  }

  /**********************
   * Store new items in session for instant availability
   * This compensates for Algolia being slow to add new annotations
   **********************/

  // storeAnnotation(annotation) {
  //   if (sessionStorage) {
  //     let arrString = sessionStorage.getItem('newAnnotations')
  //     if (arrString) {
  //       const arr = JSON.parse(arrString)
  //       const newArr = arr.concat(annotation)
  //       sessionStorage.setItem('newAnnotations', JSON.stringify(newArr))
  //     } else {
  //       sessionStorage.setItem('newAnnotations', JSON.stringify([annotation]))
  //     }
  //   }
  // }

  // removeStoredAnnotation(id) {
  //   console.log('removeStoredAnnotation')
  //   if (sessionStorage) {
  //     let arrString = sessionStorage.getItem('newAnnotations')
  //     if (arrString) {
  //       const arr = JSON.parse(arrString)
  //       const newArr = arr.filter(a => a.id !== id)
  //       sessionStorage.setItem('newAnnotations', JSON.stringify(newArr))
  //     }
  //   }
  // }

  // // Used to track new quotes in the archive. Quotes can been annotations, 
  // // which are stored on comments
  // storeComment(comment) {
  //   if (sessionStorage) {
  //     console.log('will store comment',comment)
  //     let arrString = sessionStorage.getItem('newComments')
  //     if (arrString) {
  //       const arr = JSON.parse(arrString)
  //       const newArr = arr.filter(item => item.id !== comment.id).concat(comment)
  //       sessionStorage.setItem('newComments', JSON.stringify(newArr))
  //     } else {
  //       sessionStorage.setItem('newComments', JSON.stringify([comment]))
  //     }
  //   }
  // }

  storeComment(comment) {
    this.setState((state, props) => {
      let quotes = state.newQuotes 

      return {newQuotes: quotes.concat(comment)}
    })
  }

  // removeStoredComment(id) {
  //   console.log('removeStoredComment')
  //   if (sessionStorage) {
  //     let arrString = sessionStorage.getItem('newComments')
  //     if (arrString) {
  //       const arr = JSON.parse(arrString)
  //       const newArr = arr.filter(a => a.id !== id)
  //       sessionStorage.setItem('newComments', JSON.stringify(newArr))
  //     }
  //   }
  // }

  storeBoostTarget(boostTarget) {
    if (sessionStorage) {
      console.log('storeBoostTarget',boostTarget)
      let arrString = sessionStorage.getItem('newSources')
      let item = [boostTarget]
      if (arrString) {
        const arr = JSON.parse(arrString)
        const newArr = item.concat(arr)
        sessionStorage.setItem('newSources', JSON.stringify(newArr))
      } else {
        sessionStorage.setItem('newSources', JSON.stringify(item))
      }
      console.log('boost target stored in session',boostTarget)
    }
  }

  removeStoredBoostTarget(id) {
    console.log('removeStoredBookmark')
    if (sessionStorage) {
      let arrString = sessionStorage.getItem('newSources')
      if (arrString) {
        const arr = JSON.parse(arrString)
        const newArr = arr.filter(a => a.id !== id)
        sessionStorage.setItem('newSources', JSON.stringify(newArr))
      }
    }
  }

  /**********************
   * Alerts 
   **********************/

  alert(message, button) {
    this.setState({
      alertMessage: {
        message: message,
        button: button
      }
    })
  }

  removeAlert() {
    this.setState({alertMessage: null})
  }

  /**********************
   * Posts 
   **********************/

  newPost(data) {
    // this.setState({
    //   post: {
    //     postTarget: postTarget,
    //     targetType: 'feed',
    //     post: true
    //   }
    // })
    this.setState({
      post: data
    })
  }

  closePost() {
    this.setState({
      post: null
    })
  }

  /**********************
   * Notifications 
   **********************/

  deleteNotification(id) {
    console.log('this.state',this.state)
    // Delete the notification locally
    if (this.state.systemNotes) {
      console.log('found systemNotes!')
      let notes = this.state.systemNotes.slice()
      notes = notes.filter(n => n.id !== id)
      this.setState({systemNotes: notes})
      console.log('should have set state...')
    }

    // Delete the notification on the server
    this.state.db.collection('notifications').doc(id).delete()
  }

  /**********************
   * Prompt Sign In
   **********************/

  promptSignIn(title, message, request) {
    console.log('prompt sign in')
    let d = new Date()
    this.setState({showSignIn: {
      lastPrompt: d.getTime(),
      title: title,
      message: message,
      request: request
    }})
  }

  clearPrompt() {
    this.setState({showSignIn: false})
  }

  /**********************
   * Load & Cache Fonts
   **********************/

  // loadFontCSS(name, variant) {
  //   let font = this.state.fonts.find(f => f.name === name)
  //   if (font) {
  //     console.log('return cached font')
  //     return Promise.resolve(font.css)
  //   } else {
  //     return fetchCSS(name, variant).then(fontCSS => {
  //       this.setState((state, props) => {
  //         return {fonts: state.fonts.concat({name: name, css: fontCSS})}
  //       })

  //       return fontCSS
  //     })
  //   }
  // }

  savePreference(obj) {
    if (this.state.preferences 
      && Object.keys(this.state.preferences).length > 0) {
      // They already have preferences loaded
      let newPrefs = deepMerge(this.state.preferences, obj)

      this.state.db.collection('preferences').doc(this.state.preferences.id)
      .update(saveObj(newPrefs)).then(() => {
        this.setState({preferences: newPrefs})
      })
    } else if (this.state.user) {
      // Only logged in users can have preferences
      const prefs = Object.assign({}, obj, {uid: this.state.authUser.uid})
      this.state.db.collection('preferences').add(prefs).then(docRef => {
        docRef.get().then(doc => {
          this.setState({preferences: docObj(doc)})
        })
      })
    }
  }

  deletePreference(key) {
    if (this.state.preferences 
      && Object.keys(this.state.preferences).includes(key)) {
        let newPrefs = Object.assign({}, this.state.preferences)
        delete newPrefs[key]
        console.log('updated prefs',newPrefs)
        this.state.db.collection('preferences').doc(this.state.preferences.id)
        .set(saveObj(newPrefs)).then(() => {
          this.setState({preferences: newPrefs})
        })
    }
  }

  async reCAPTCHA(action, callback) {
    window.grecaptcha && window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(recaptchaKey, {action: action})
      .then(token => {
        // Server records the score for this action
        console.log('callback', callback)
        callback && callback(token).then(() => {
          console.log('completed callback')
        })
      })
    })
  }

  // Record user reCAPTCHA score for an action 
  async actionReCAPTCHA(action) {
    const recordAction = async (token) => {
      const record = this.state.functions.httpsCallable('recordReCAPTCHA')
      record({token: token})
    }

    await this.reCAPTCHA(action, recordAction)
  }

  // When a user uploads an image for a comment, then cancels, call this 
  // function to delete the image
  async deleteCancelled(locations) {
    console.log('Replace this function')
    // for (var loc of locations) {
    //   const fileName = loc.replace('userUploads/','')
    //   this.state.db.collection('uploads').doc(fileName).delete()
    // }
  }

  togglePalette() {
    let saved = window.localStorage.getItem(storageName('spirited-palette'))
    console.log('saved palette',saved)

    if (saved === 'dark') {
      this.setState({theme: defaultTheme('light')})
      window.localStorage.setItem(
        storageName('spirited-palette'),
        'light'
      )
    } else if (saved === 'light' || !saved) {
      // If there's no saved setting defaultTheme uses the light palette
      // Switch to the dark palette
      this.setState({theme: defaultTheme('dark')})
      window.localStorage.setItem(
        storageName('spirited-palette'),
        'dark'
      )
    }
  }

  setArticleTheme(data) {
    this.setState({articleTheme: data})
  }

  storeAudioEl(node) {
    console.log('store audio el')
    const updateCurrentTime = event => {
      if (this.state.audioEl) {
        this.setState({audioCurrentTime: this.state.audioEl.currentTime})
      }
    }

    const updateDuration = event => {
      if (this.state.audioEl) {
        this.setState({audioDuration: this.state.audioEl.duration})
      }
    }

    const endAudio = event => {
      this.setState({audioPlaying: false})
    }

    if (this.state.audioEl) {
      this.state.audioEl.removeEventListener('timeupdate', updateCurrentTime)
      this.state.audioEl.removeEventListener('canplay', updateDuration)
      this.state.audioEl.removeEventListener('ended', endAudio)
    }

    if (node) {
      node.addEventListener('timeupdate', updateCurrentTime)
      node.addEventListener('canplay', updateDuration)
      node.addEventListener('ended', endAudio)

      this.setState({audioEl: node})
    }
  }

  async loadStreamingAudio(url, route, title) {
    if (this.state.streamingUrl === url) {
      // Do not reload the same url
      return
    }

    this.setState({
      streamingUrl: url,
      audioRoute: route,
      audioTitle: title
    })

    if (this.state.audioEl.canPlayType('application/vnd.apple.mpegurl')) {
      this.state.audioEl.src = url
      this.setState({audioLoaded: true})
    } else {
      import('hls.js').then(({default: Hls}) => {
        console.log('Hls loaded', Hls)
        if (!Hls.isSupported()) return

        const hls = new Hls()
        hls.attachMedia(this.state.audioEl)
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log('media attached');
          hls.loadSource(url);
          hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
            console.log(
              'manifest loaded, found ' + data.levels.length + ' quality level'
            )
            this.setState({audioLoaded: true})

          })
        })
      })
    }

    // } else if (Hls.isSupported()) {
    //   const hls = new Hls()
    //   hls.attachMedia(this.state.audioEl)
    //   hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    //     console.log('media attached');
    //     hls.loadSource(url);
    //     hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
    //       console.log(
    //         'manifest loaded, found ' + data.levels.length + ' quality level'
    //       )
    //       this.setState({audioLoaded: true})

    //     })
    //   })
    // } 
  }

  audioPlay(bool) {
    if (this.state.audioEl) {
      if (bool) {
        this.state.audioEl.play()
        this.setState({audioPlaying: true})
      } else {
        this.state.audioEl.pause()
        this.setState({audioPlaying: false})
      }
    }
  }

  setLastEmailLogin(email) {
    this.setState({lastEmailLogin: email})
  }

  setAtBottom(bool) {
    this.setState({atBottom: bool})
  }

  addChapterMenu(menuObj) {
    this.setState((state, props) => {
      let newMenus = state.chapterMenus
        .filter(m => m.bookId !== menuObj.bookId)
        .concat(menuObj)

      return {
        chapterMenus: newMenus
      }
    })
  }

  removeChapterMenu(bookId) {
    this.setState((state, props) => {
      return {
        chapterMenus: state.chapterMenus.filter(m => m.bookId !== bookId)
      }
    })
  }

  toggleMute(aliasId) {
    let newList = this.state.muted?.slice() || []
    const muted = this.state.muted.includes(aliasId)

    if (muted) {
      newList = newList.filter(a => a !== aliasId)
    } else {
      newList.push(aliasId)
    }

    this.setState({
      muted: newList
    })

    this.state.db.collection('users').doc(this.state.user.id)
    .collection('preferences').doc('muted').set({list: newList})
  }

  getNewPosts() {
    const posts = this.state.newPosts.slice();
    this.setState({newPosts: []});
    return posts; 
  }

  addNewPosts(comments) {
    this.setState((state, props) => {
      return {newPosts: state.newPosts.concat(comments)};
    })
  }

  async updateVerificationStatus(status) {
    const fn = this.state.functions
      .httpsCallable('updateVerificationStatus');

    return fn({status: status});
  }

  render() {
    return (
      <React.Fragment>
        <Head />
        <DatabaseContext.Provider value={this.state}>
          <ErrorBoundary>
            <BrowserRouter>
              <audio ref={this.storeAudioEl} />
              <WebAppRouter theme={this.state.theme} />
            </BrowserRouter>
          </ErrorBoundary>
        </DatabaseContext.Provider>
      </React.Fragment>
    );
  }
}